import React, { useState, useContext, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { UserContext } from '../context/userContext'
import { useNavigate } from 'react-router-dom'



const formats = ['header', 'bold', 'italic', 'underline']

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ]
}

const POST_CATEGORIES = ["Select", "Myanmar", "Indonesian", "Indian", "Phlipino"]

const CreatePost = () => {
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('Uncategorized')
    const [description, setDescription] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const navigate = useNavigate()

    const { currentUser } = useContext(UserContext)
    const token = currentUser?.token;

    //redirect to login page if not logged in
    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [])

    return (
        <section className="create__post">
            <div className="container">
                <h2>Create post</h2>
                <p className="form__error-message">
                    This is an error message
                </p>
                <form className="form create-post__form">
                    <input type="text" placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} autoFocus />
                    <select name="category" value={category} onChange={e => setCategory(e.target.value)}>
                        {
                            POST_CATEGORIES.map(cat => <option key={cat}>{cat}</option>)
                        }
                    </select>
                    <ReactQuill className='ql_editor' modules={modules} formats={formats} value={description} onChange={setDescription} />
                    <input type="file" onChange={e => setThumbnail(e.target.value[0])} accept='png, jpg, jpeg, jfif' />
                    <button className="btn primary" type='submit'>create</button>
                </form>
            </div>
        </section>
    )
}

export default CreatePost
