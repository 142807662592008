import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

import { UserContext } from '../context/userContext'

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "../components/ui/carousel"



const Login = () => {
    const [userData, setUserData] = useState({
        email: '',
        password: ''

    })

    const [error, setError] = useState("")
    const navigate = useNavigate()
    const { setCurrentUser } = useContext(UserContext)

    const changeInputHandler = (e) => {
        setUserData(prevState => {
            return { ...prevState, [e.target.name]: e.target.value }
        })
    }
    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/login`, userData)
            const user = await response.data;
            setCurrentUser(user)
            navigate('/')
        } catch (err) {
            setError(err.response.data.message)

        }

    }

    return (
        <section className="register">
            <div className="container">
                <h2>Login</h2>
                <form className="form login__form" onSubmit={loginUser}>
                    {error && <p className="form__error-message">{error}</p>}
                    <input type="text" placeholder='email' name='email' value={userData.email} onChange={changeInputHandler} autoFocus />
                    <input type="password" placeholder='password' name='password' value={userData.password} onChange={changeInputHandler} />
                    <button type="submit" className='btn primary'>Login</button>
                    <small>Dont have an account ? <Link to='/register' >sign up</Link></small>
                </form>
                <Carousel>
                    <CarouselContent>
                        <CarouselItem>test</CarouselItem>
                        <CarouselItem>teseererererer</CarouselItem>
                        <CarouselItem>dfdfdfdf</CarouselItem>
                    </CarouselContent>
                    <CarouselPrevious />
                    <CarouselNext />
                </Carousel>



            </div>
        </section>
    )
}

export default Login
