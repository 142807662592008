import React, { useState } from 'react'
import { DUMMY_POSTS } from '../data'
import PostItems from '../components/PostItems'


const AuthorPosts = () => {
    const [posts, setPosts] = useState(DUMMY_POSTS)
    return (
        <section >
            <div className="container post__container">
                {
                    posts.map(({ id, thumbnail, category, title, description, authorID }) =>
                    (
                        <PostItems
                            key={id}
                            postID={id}
                            thumbnail={thumbnail}
                            category={category}
                            title={title}
                            description={description}
                            authorID={authorID}
                        />
                    ))
                }
            </div>

        </section>
    )
}

export default AuthorPosts
