import Thumbnail1 from "./images/nantee.png"
import Thumbnail2 from "./images/SAN_SAN.png"
import Thumbnail3 from "./images/jaspreet.jfif"
import Thumbnail4 from "./images/rose_phil_transfer.jpg"
export const DUMMY_POSTS = [
    {
        id: '4',
        thumbnail: Thumbnail1,
        category: 'Myanmar',
        title: 'New Myanmar',
        description: 'She is new to Singapore, she can do all type of works',
        authorID: 4
    },
    {
        id: '1',
        thumbnail: Thumbnail2,
        category: 'Myanmar',
        title: 'New Myanmar',
        description: 'She can do all types of work.',
        authorID: 4
    },
    {
        id: '2',
        thumbnail: Thumbnail3,
        category: 'Indian',
        title: 'Punjabi Transfer',
        description: 'She has worked 6 months in a house looking for transfer, direct transfer',
        authorID: 4
    },
    {
        id: '3',
        thumbnail: Thumbnail4,
        category: 'Philipino',
        title: 'Philipino Transfer',
        description: 'Name: SURATMI\nDate of birth: JUNE 26, 1982\nPlace of birth: WONOGIRI\nTb & Bb : 157cm & 58kg\nAge: 42\nAddress: Rt03 Rw06, Bayemharjo, Giritontro, Wonogiri, Central Java.\nDeparture airport: YOGYAKARTA INTERNATIONAL AIRPORT.\nReligion: Islam\nLast degree: SLTA\nSiblings: the 4th out of 4 siblings.\nMarriage status: MARRIED\nMention child n age: 0\nAllergies: NO\noperation: NO\nhandle pork: CAN HANDLE, CANNOT EAT.\nHoliday how many times a month: 1x / 2x\nEver worked anywhere and for how long. : HONG KONG 10th (care giver)\nSINGAPORE 1st (care giver).\n',

        authorID: 5
    },
]