import React, { useState, useEffect } from 'react'
import PostItems from './PostItems'
import Loader from '../components/Loader'
import axios from 'axios'
const Posts = () => {

    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchPosts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts`)
                setPosts(response?.data)
            } catch (err) {
                console.log(err)
            }
            setIsLoading(false)


        }
        fetchPosts();
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <section className="posts">
            <div className="container post__container">
                {
                    posts.map(({
                        _id: id,
                        title,
                        helperName,
                        refCode,
                        category,
                        sgExp,
                        salary,
                        off,
                        neg,
                        dob,
                        height,
                        weight,
                        marriage,
                        children,
                        child_detail,
                        religion,
                        language1,
                        language2,
                        education,
                        description,
                        thumbnail,
                        creator,
                        createdAt
                    }) =>

                    (
                        <PostItems
                            key={id}
                            postID={id}

                            title={title}
                            helperName={helperName}
                            refCode={refCode}
                            category={category}
                            sgExp={sgExp}
                            salary={salary}
                            off={off}
                            neg={neg}
                            dob={dob}
                            height={height}
                            weight={weight}
                            marriage={marriage}
                            children={children}
                            child_detail={child_detail}
                            religion={religion}
                            language1={language1}
                            language2={language2}
                            education={education}
                            description={description}
                            thumbnail={thumbnail}

                            authorID={creator}
                            createdAt={createdAt}
                        />
                    ))
                }
            </div>

        </section>

    )
}

export default Posts
