import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/userContext'

const Logout = () => {
    const { setCurrentUser } = useContext(UserContext)
    const naviagate = useNavigate();
    setCurrentUser(null)
    naviagate('/login')

    return (
        <></>
    )
}

export default Logout
