import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <ul className="footer__categories">
                <li><Link to='/posts/categories/Myanmar'>Myanmar Helpers</Link></li>
                <li><Link to='/posts/categories/Indonesian'>Indonesian</Link></li>
                <li><Link to='/posts/categories/Philipino'>Philipino</Link></li>
                <li><Link to='/posts/categories/Mizoram'>Mizoram maids</Link></li>
                <li><Link to='/posts/categories/Darjeeling'>Darjeeling maids</Link></li>
                <li><Link to='/posts/categories/Punjab'>Punjab Helpers</Link></li>
                <li><Link to='/posts/categories/Others'>Transfer Maids</Link></li>
            </ul>
            <div className="footer__copyright">
                <small>All rights resevered &copy; HippoMaid.com</small>
            </div>
        </footer>
    )
}

export default Footer
