import React, { useState, useContext } from 'react'
import Logo from '../images/favicon.ico'
import { Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from "react-icons/ai"

import { UserContext } from '../context/userContext'


const Header = () => {
    //for desktop nav showing is true, for mobile it is not shown
    const [isNavShowing, setIsNavShowing] = useState(window.innerWidth > 800 ? true : false);
    const { currentUser } = useContext(UserContext)


    //function to close the nav only for mobile.
    const closeNavHandler = () => {
        if (window.innerWidth < 800) {
            setIsNavShowing(false)
        } else {
            setIsNavShowing(true)
        }
    }

    return (
        <nav>
            <div className="conatainer nav__container ">
                <div className='nav__logo-name'>
                    <Link to="/" className='nav__logo' >
                        <img src={Logo} alt="Logo" onClick={closeNavHandler} />
                    </Link>
                    <div>
                        <h1><span className="nav__site-name">Hippo</span>Maid</h1>
                        <span>Laregest collection of direct helpers</span>
                    </div>
                </div>
                {currentUser?.id && isNavShowing && <ul className="nav__menu">
                    <li><Link to='/profile/sdfd' onClick={closeNavHandler}>Abdul Rahman</Link></li>
                    <li><Link to='/create' onClick={closeNavHandler}>Create Post</Link></li>
                    <li><Link to='/authors' onClick={closeNavHandler} >Overseas Suppliers</Link></li>
                    <li><Link to='/logout' onClick={closeNavHandler} >Logout</Link></li>
                </ul>}
                {!currentUser?.id && isNavShowing && <ul className="nav__menu">
                    <li><Link to='https://wa.me/+6582612108' onClick={closeNavHandler} > contact +6582612108</Link></li>
                    <li><Link to='/' onClick={closeNavHandler} >Direct Helpers</Link></li>
                    <li><Link to='/authors' onClick={closeNavHandler} >Overseas suppliers</Link></li>
                    <li><Link to='/login' onClick={closeNavHandler} >login</Link></li>
                </ul>}
                <button className="nav__toggle-btn" onClick={() => setIsNavShowing(!isNavShowing)}>
                    {isNavShowing ? <AiOutlineClose /> : <FaBars />}
                </button>
            </div>


        </nav>
    )
}

export default Header
