import React, { useContext, useEffect } from 'react'
import { UserContext } from '../context/userContext';
import { Link, useNavigate } from 'react-router-dom';

const DeletePost = () => {
    const navigate = useNavigate()
    const { currentUser } = useContext(UserContext)
    const token = currentUser?.token;
    //redirect to login page if not logged in

    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [])


    return (

        <div>
            <Link className='btn sm danger'>Delete</Link>
        </div>
    )
}

export default DeletePost
