import React from 'react'
import { Link } from 'react-router-dom'
import PostAuthor from './PostAuthor'


const PostItems = ({
    postID,
    authorID,
    title,
    helperName,
    refCode,
    category,
    sgExp,
    salary,
    off,
    neg,
    dob,
    height,
    weight,
    marriage,
    children,
    child_detail,
    religion,
    language1,
    language2,
    education,
    description,
    thumbnail,
    createdAt }) => {
    const shortDescription = description.length > 24 ?
        description.substr(0, 24) + '...' :
        description;
    const shortTitle = title.length > 30 ?
        title.substr(0, 30) + '...' :
        title
    let ageInYears = null;

    let formatedDob = null
    if (dob) {
        const milliseconds = Date.parse(dob);
        const dateObject = new Date(milliseconds);
        const today = new Date();
        const diffInMs = today.getTime() - dateObject.getTime();
        //  ageInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365)); // Account for leap years
        ageInYears = today.getFullYear() - dateObject.getFullYear();
        // Adjust for birthdays not yet passed this year (more precise)
        const birthMonth = dateObject.getMonth();
        const birthDay = dateObject.getDate();
        const currentMonth = today.getMonth();
        const currentDay = today.getDate();
        formatedDob = dateObject.toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });


        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
            ageInYears--;
        }
    }

    return (
        <article className="post">
            <div className="post__thumbnail">
                <img src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${thumbnail}`} alt={title} />
            </div>
            <div className="post__content">
                <Link to={`/posts/${postID}`}>
                    <h3>{helperName}</h3>
                    <table className="w-full table-auto border shadow-md rounded-md overflow-hidden text-xs text-gray-500 font-semibold">
                        <tbody className=''>
                            <tr>
                                <td className="px-2 py-1 border-b dark:border-gray-700">Ref: {refCode || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{category || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{sgExp || '##'}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-1 border-b dark:border-gray-700">${salary || '###'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{off || '##'} off </td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{neg || '##'}</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-1 border-b dark:border-gray-700">Age: {ageInYears || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{formatedDob || '#'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">Ht: {height || '##'} cm</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{marriage || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{children || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">wt: {weight || '##'} kg</td>
                            </tr>
                            <tr>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{religion || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{language1 || '##'}</td>
                                <td className="px-2 py-1 border-b dark:border-gray-700">{language2 || '##'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Link>

                <Link to={`/posts/${postID}`}>
                    <div className='text-sm p-2 text-right'>
                        <p> Details..</p>
                    </div>
                </Link>
                <div className="post__footer">
                    <PostAuthor authorID={authorID} createdAt={createdAt} />
                    <Link to={`https://wa.me/+6582612108?text= Hi, I am interested in helper ${helperName} ref:${refCode}. Please contact me ASAP`} target="_blank" className='btn category'>WatsApp</Link>
                </div>
            </div>
        </article>

    )
}

export default PostItems
